/* .loader {
  width: 80px;
  aspect-ratio: 1;
  background: #D17542;
  display: grid;
  transform-origin: top right;
  animation: l5-0 4s infinite linear;
}
.loader::before,
.loader::after {
  content: "";
  grid-area: 1/1;
  background: #3B7AAD;
  transform-origin: inherit;
  animation: inherit;
  animation-name: l5-1;
  animation-duration: 1s;
}
.loader::after {
  background: #5F978A;
  --s: 60deg;
}

@keyframes l5-0 {
  0%,
  5% {
    transform: rotate(0);
  }
  25%,
  30% {
    transform: rotate(90deg);
  }
  50%,
  55% {
    transform: rotate(180deg);
  }
  75%,
  80% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes l5-1 {
  50% {
    transform: rotate(var(--s, 30deg));
  }
  100% {
    transform: rotate(0);
  }
} */
/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  aspect-ratio: 1;
  transform: translate(-100%, 100%);
  animation: l14 2s infinite;
}
@keyframes l14 {
  0% {
    box-shadow: 50px -50px #3b7aad, 50px -50px #d17542, 50px -50px #0000,
      50px -50px #0000, 50px -50px #0000;
  }
  25% {
    box-shadow: 100px -50px #0000, 50px -50px #d17542, 50px -50px #5f978a,
      50px -50px #0000, 50px -50px #0000;
  }
  50% {
    box-shadow: 100px -50px #0000, 50px -100px #0000, 50px -50px #5f978a,
      50px -50px #d94b57, 50px -50px #0000;
  }
  75% {
    box-shadow: 100px -50px #0000, 50px -100px #0000, 0px -50px #0000,
      50px -50px #d94b57, 50px -50px #3b7aad;
  }
  100% {
    box-shadow: 100px -50px #0000, 50px -100px #0000, 0px -50px #0000,
      50px 0px #0000, 50px -50px #3b7aad;
  }
}
