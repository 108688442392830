#after-loading {
  font-size: x-large;
  font-weight: normal;
}
.gradient-text {
  background-color: #f3ec78;
  background-image: linear-gradient(
    270deg,
    #ec873a 0%,
    #e23d67 28%,
    #a758e3 64.5%,
    #5bc0f6 100%
  );
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  background-clip: text;
}

@media screen and (max-width: 769px) {
  #after-loading {
    font-size: large;
    font-weight: normal;
  }
}
