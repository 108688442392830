@media screen and (min-width: 769px) {
  html,
  body {
    font-family: Manrope;
    background: radial-gradient(
      102.37% 29.37% at 27.64% 40.07%,
      #f2e3d3 0%,
      #fff9f0 100%
    );
    margin: 0;
    height: 100%;
    overflow: hidden;
  }

  .landing-page {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 80px;
    /* padding: 0px 80px; */
  }
  .left-side {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 50%;
    height: 100vh;
    padding-left: 80px;
  }

  .logo {
    flex-shrink: 0;
    padding-top: 16px;
  }
  .logo img {
    width: 109px;
    height: 72px;
  }
  .catch-phrase-container {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
  }
  .catch-phrase {
    width: 645px;
    color: var(--Black, #1a1a1a);
    /* leading-trim: both;
  text-edge: cap; */
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .gradient-text {
    background-color: #f3ec78;
    background-image: linear-gradient(
      270deg,
      #ec873a 0%,
      #e23d67 28%,
      #a758e3 64.5%,
      #5bc0f6 100%
    );
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    background-clip: text;
  }

  .second-catch-phrase {
    font-size: 28px;
    font-style: inherit;
    font-weight: 300;
    line-height: normal;
  }
  .button {
    display: flex;
    width: 240px;
    height: 64px;
    padding: 0px 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid rgba(204, 204, 204, 0.3);
    background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(204, 204, 204, 0) 0%,
        rgba(204, 204, 204, 0.15) 100%
      ),
      linear-gradient(
        270deg,
        #d58547 0%,
        #da4067 33%,
        #9645d3 66%,
        #52b2e6 100%
      );
    box-shadow: 0px 0px 4px 2px rgba(255, 255, 255, 0.3) inset;
    color: var(--White, #fff);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .semi-footer {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    display: none;
  }

  .semi-footer div {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .semi-footer div svg {
    width: 20px;
    height: 20px;
  }

  .footer {
    display: inline-flex;
    align-items: flex-start;
    padding-bottom: 16px;

    gap: 16px;
    color: #999;
    /* leading-trim: both;
  text-edge: cap; */
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .footer div:nth-child(1),
  .footer div:nth-child(2) {
    text-decoration-line: underline;
  }
  .footer div:nth-child(3) span {
    text-decoration-line: underline;
  }

  .right-side {
    display: flex;
    justify-content: center; /* Center the contents horizontally */
    align-items: center;
    gap: 25px;
    width: 50%;
    height: 100vh;
  }

  .carousel-up {
    height: 2100px;
    width: 280px;
    position: relative;
    display: flex;
    /* margin: auto; */
    overflow: hidden;
  }

  .carousel-up::after {
    height: 2098px;
    width: 278px;
    content: "";
    position: absolute;
  }

  .image-item-up {
    height: 210px;
    width: 280px;
    position: absolute;
    /* padding-top: 20px; */
    font-size: 25px;
    line-height: 50px;
    text-align: center;
  }
  .image-item-up > img {
    width: 280px;
    height: 190px;
    border-radius: 15px;
    object-fit: cover;
  }

  .images-up {
    /* display: flex;
  flex-direction: column;
  gap: 20px; */
    position: relative;
    bottom: -210px;
  }

  .carousel-down {
    height: 4400px;
    width: 280px;
    position: relative;
    display: flex;

    /* margin: auto; */
    overflow: hidden;
  }

  .carousel-down::after {
    height: 4398px;
    width: 278px;
    content: "";
    position: absolute;
  }

  .image-item-down {
    height: 440px;
    width: 280px;
    position: absolute;
    /* padding-top: 20px; */
    font-size: 25px;
    line-height: 50px;
    text-align: center;
  }
  .image-item-down > img {
    width: 280px;
    height: 420px;
    border-radius: 15px;
    object-fit: cover;
  }

  .images-down {
    /* display: flex;
  flex-direction: column;
  gap: 20px; */
    position: relative;
    bottom: -440px;
  }
  .overlay-img {
    position: absolute;
  }

  .carousel-hor {
    display: none;
  }
  .footer-mobile {
    display: none;
  }
}

@media screen and (max-width: 769px) {
  html,
  body {
    font-family: Manrope;
    background: radial-gradient(
      102.37% 29.37% at 27.64% 40.07%,
      #f2e3d3 0%,
      #fff9f0 100%
    );
    margin: 0;
    height: 844px;
  }
  .right-side {
    display: none;
  }
  .landing-page {
    display: flex;
    justify-content: space-between;
  }
  .left-side {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    overflow: hidden;
  }
  .logo {
    flex-shrink: 0;
    padding-top: 16px;
    width: 342px;
  }
  .logo img {
    width: 109px;
    height: 72px;
    flex-shrink: 0;
  }
  .catch-phrase-container {
    display: flex;
    width: 342px;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
  }
  .catch-phrase {
    align-self: stretch;
    color: var(--Black, #1a1a1a);
    font-family: Manrope;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .gradient-text {
    background-color: #f3ec78;
    background-image: linear-gradient(
      270deg,
      #ec873a 0%,
      #e23d67 28%,
      #a758e3 64.5%,
      #5bc0f6 100%
    );
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    background-clip: text;
  }
  .second-catch-phrase {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .button {
    display: flex;
    height: 48px;
    padding: 0px 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(204, 204, 204, 0) 0%,
        rgba(204, 204, 204, 0.15) 100%
      ),
      linear-gradient(
        270deg,
        #d58547 0%,
        #da4067 33%,
        #9645d3 66%,
        #52b2e6 100%
      );
    color: var(--White, #fff);
    text-align: center;
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .semi-footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    color: var(--Dark-gray, #333);
    text-align: center;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .semi-footer div {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .semi-footer div svg {
    width: 20px;
    height: 20px;
  }
  .carousel-hor {
    height: 186px;
    width: 3000px;
    position: relative;
    display: flex;
    /* margin: auto; */
    overflow: hidden;
  }

  .carousel-hor::after {
    height: 186px;
    width: 2998px;
    content: "";
    position: absolute;
  }

  .image-item-hor {
    height: 186px;
    width: 280px;
    position: absolute;
    /* padding-top: 20px; */
    font-size: 25px;
    line-height: 50px;
    text-align: center;
  }
  .image-item-hor > img {
    width: 280px;
    height: 186px;
    border-radius: 15px;
    object-fit: cover;
  }

  .images-hor {
    position: relative;
    right: -300px;
  }
  .footer {
    display: none;
  }
  .footer-mobile {
    display: inline-flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
    color: #999;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .footer-mobile div:nth-child(1) {
    display: flex;
    gap: 10px;
    text-decoration-line: underline;
  }
  .footer-mobile div:nth-child(2) span {
    text-decoration-line: underline;
  }
}
